/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { LogoutOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  Select,
  DatePicker,
  Space,
  Typography,
  Button,
  Tag,
  Table,
  Radio,
  message,
  Checkbox,
} from "antd";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import {
  AreaChart,
  ResponsiveContainer,
  Area,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import "../index.css";
import LoaderComp from "../Utility/LoaderComp";
import { useCookies } from "react-cookie";
import { destroyToken } from "../API/User";
import getLogo from "../helpers/getLogo";
import getCurrencySymbol from "../helpers/getCurrencySymbol";
import selectCountryOptions from "../helpers/selectCountryOptions";

const { RangePicker } = DatePicker;
const { Text } = Typography;

const createDate = (today) => {
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  return [dd, mm, yyyy];
};

function AFSDash(props) {
  const [userName, setUserName] = useState("");
  const [cookies, , removeCookie] = useCookies(["my_token"]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchDate, setSearchDate] = useState(0);

  const navigate = useNavigate();

  const [imp, setImp] = useState("");
  const [earning, setEarning] = useState("");
  const [Pv, setPv] = useState("");
  const [clicks, setClicks] = useState("");
  const [impGraph, setImpGraph] = useState([]);
  const [revGraph, setRevGraph] = useState([]);
  const [pvGraph, setPvGraph] = useState([]);
  const [clicksGraph, setClicksGraph] = useState([]);
  const [dateRangeVal, setDateRangeVal] = useState([null, null]);
  const [quickDate, setQuickDate] = useState("");
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState([]);
  const [selectChannelOptions, setSelectChannelOptions] = useState([]);
  const [filterSubmit, setFilterSubmit] = useState(false);
  const [geoToggle, setGeoToggle] = useState(false);
  const [tzText, setTzText] = useState("");
  const [timezone, setTimezone] = useState("ist");
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [clientCurrency, setClientCurrency] = useState(["USD", "INR"]);

  const [currConvertToggle, setCurrConvertToggle] = useState(false);
  const [currToggleShow, setCurrToggleShow] = useState(false);
  const retryCountRef = useRef(0);
  const intervalRef = useRef(null);
  const [adsenseAccount, setAdsenseAccount] = useState("");
  const [multiUser, setMultiUser] = useState(false);
  const [disabledOptions, setDisabledOptions] = useState([]);
  const [clientRevShare, setClientRevShare] = useState(1);
  const [channelData, setChannelData] = useState({});
  const [avgCtr, setAvgCtr] = useState();
  const [avgCpc, setAvgCpc] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const [searchInput, setSearchInput] = useState("");
  const [channelCheck, setChannelCheck] = useState(false);
  const [token, setToken] = useState(false);
  const [isUnibots, setIsUnibots] = useState(true);
  const [lastUpdated, setLastUpdated] = useState();

  let columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 120,
      fixed: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.date > b.date,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Channel Name",
      dataIndex: "channelName",
      key: "channelName",
      width: 200,
    },
    {
      title: "Channel ID",
      dataIndex: "channelId",
      key: "channelId",
      width: 160,
    },
    {
      title: "Country",
      dataIndex: "countryName",
      key: "countryName",
      width: 150,
      sorter: (a, b) => a.countryName > b.countryName,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Your Share (USD)",
      dataIndex: "rev",
      key: "rev",
      render: (_, record) => {
        return isNaN(record.rev)
          ? 0
          : getCurrencySymbol(clientCurrency[0]) +
              (0.85 * Number(record.rev)).toFixed(2);
      },
      width: 100,
    },
    {
      title: `Estimated Earnings (${
        currConvertToggle ? `${clientCurrency[1]}` : `${clientCurrency[0]}`
      })`,
      dataIndex: "rev",
      key: "rev",
      render: (_, record) => {
        return isNaN(record.rev)
          ? 0
          : getCurrencySymbol(
              currConvertToggle ? clientCurrency[1] : clientCurrency[0]
            ) + Number(record.rev).toFixed(2);
      },
      width: 100,
      sorter: (a, b) => a.rev - b.rev,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Page Views",
      dataIndex: "pv",
      key: "pv",
      width: 100,
      sorter: (a, b) => a.pv - b.pv,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Impressions",
      dataIndex: "imp",
      key: "imp",
      width: 110,
      sorter: (a, b) => a.imp - b.imp,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "CTR (%)",
      dataIndex: "impCTR",
      key: "impCTR",
      render: (_, record) => {
        return Number(record.impCTR).toFixed(2) + "%";
      },
      width: 100,
      sorter: (a, b) => a.impCTR - b.impCTR,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      width: 100,
      sorter: (a, b) => a.clicks - b.clicks,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "CPC",
      dataIndex: "cpc",
      key: "cpc",
      render: (_, record) => {
        return (
          // props.client === "zippinder" ||
          getCurrencySymbol(
            currConvertToggle ? clientCurrency[1] : clientCurrency[0]
          ) + Number(record.cpc).toFixed(2)
        );
      },
      width: 100,
      sorter: (a, b) => a.cpc - b.cpc,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Imp Spam",
      dataIndex: "impSpam",
      key: "impSpam",
      width: 110,
      sorter: (a, b) => parseFloat(a["impSpam"]) - parseFloat(b["impSpam"]),
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text, record) => {
        if (text === "NA") {
          return (
            <span>
              <Space align="center">
                <Tag>{""}</Tag>
              </Space>
            </span>
          );
        }
        const ranges = [
          { upperBound: 0.25, color: "green", label: "0-0.25%" },
          { upperBound: 0.5, color: "green", label: "0.25-0.5%" },
          { upperBound: 1.0, color: "yellow", label: "0.5-1.0%" },
          { upperBound: 1.5, color: "#f50", label: "1.0-1.5%" },
          { upperBound: 2.0, color: "#f50", label: "1.5-2.0%" },
          { upperBound: 3.0, color: "#f50", label: "2.0-3.0%" },
          { upperBound: Infinity, color: "#f50", label: ">3.0%" },
        ];

        let range = ranges.find((range) => text <= range.upperBound);

        return (
          <span>
            <Space align="center">
              <Tag color={range.color}>{range.label}</Tag>
            </Space>
          </span>
        );
      },
    },
    {
      title: "Click Spam",
      dataIndex: "clickSpam",
      key: "clickSpam",
      width: 100,
      sorter: (a, b) => parseFloat(a["clickSpam"]) - parseFloat(b["clickSpam"]),
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text, record) => {
        if (text === "NA") {
          return (
            <span>
              <Space align="center">
                <Tag>{""}</Tag>
              </Space>
            </span>
          );
        }
        const ranges = [
          { upperBound: 5, color: "green", label: "1-5%" },
          { upperBound: 10, color: "green", label: "5-10%" },
          { upperBound: 15, color: "yellow", label: "10-15%" },
          { upperBound: 25, color: "#f50", label: "15-25%" },
          { upperBound: 30, color: "#f50", label: "25-30%" },
          { upperBound: 40, color: "#f50", label: "30-40%" },
          { upperBound: Infinity, color: "#f50", label: ">40%" },
        ];

        let range = ranges.find((range) => text <= range.upperBound);

        return (
          <span>
            <Space align="center">
              <Tag color={range.color}>{range.label}</Tag>
            </Space>
          </span>
        );
      },
    },
  ];

  const errorMessage = (type) => {
    messageApi.open({
      type: "warning",
      content: "Failed to fetch client data, please refresh!",
      duration: 5,
    });
  };

  function filterTableColumns() {
    let col = columns.filter((obj) => {
      if (clientRevShare !== 1) {
        return !geoToggle
          ? obj.title !== "Country"
          : obj && obj.title !== "Imp Spam" && obj.title !== "Click Spam";
      } else {
        return !geoToggle
          ? obj.title !== "Country" && obj.title !== "Your Share (USD)"
          : obj.title !== "Your Share (USD)" &&
              obj.title !== "Imp Spam" &&
              obj.title !== "Click Spam";
      }
    });
    col = col.filter(
      (obj) =>
        multiUser || (obj.title !== "Imp Spam" && obj.title !== "Click Spam")
    );

    return col;
  }

  useEffect(() => {
    const my_token = cookies.my_token;
    setToken(my_token.split(".").slice(0, 3).join("."));
    let userData = {};
    // Extract user data from the cookie
    if (my_token) {
      userData = JSON.parse(atob(my_token.split(".")[3]));
      setToken(my_token.split(".").slice(0, 3).join("."));
    }

    setUserName(userData?.name);
    if (userData?.timezone === "IST") setTimezone("ist");
    else setTimezone("pst");
  }, [cookies]);

  useEffect(() => {
    const currentUrl = window.location.href;
    setIsUnibots(!currentUrl.includes("revcompass"));
    var today = new Date();
    var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

    var d = createDate(today);
    const d1 = d[2] + "-" + d[1] + "-" + d[0];
    d = createDate(firstDay);
    const d2 = d[2] + "-" + d[1] + "-" + d[0];
    setDateRangeVal([d2, d1]);

    setSearchDate(!searchDate);

    async function accountCheck() {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_ADSENSE_URL}/client_data`,
          {
            data: {
              clientName: userName,
              type: "AFS",
            },
          },
          {
            headers: {
              // AuthKey: process.env.REACT_APP_CLIENTDATA_AUTHKEY,
              Authorization: token,
            },
          }
        );

        if (res.data.data.currency_conversion.exists) {
          setCurrToggleShow(true);
          setClientCurrency(res.data.data.currency_conversion.value);
        }

        if (res.data.data.internal_user || userName === "Aditya_master")
          setMultiUser(true);
        else setMultiUser(false);

        const sipData = res.data.data.sip || {};
        const poaData = res.data.data.poa || {};

        setChannelData({ sip: sipData, poa: poaData });

        if (sipData.ist?.length > 0 || sipData.pst?.length > 0) {
          if (poaData.ist?.length > 0 || poaData.poa?.length > 0) {
            setDisabledOptions([]);
            setAdsenseAccount("sip");
          } else {
            setDisabledOptions(["poa"]);
            setAdsenseAccount("sip");
          }
        } else if (poaData.ist?.length > 0 || poaData.pst?.length > 0) {
          setDisabledOptions(["sip"]);
          setAdsenseAccount("poa");
        } else {
          setDisabledOptions(["sip", "poa"]);
          setAdsenseAccount("sip");
        }

        // getPoaUsers();
        if (res.data.data.default_account === "POA") setAdsenseAccount("poa");
      } catch (error) {
        errorMessage();
        console.error("An error occurred during account check:", error.message);
      }
    }

    if (userName) accountCheck();
  }, [userName]);

  useEffect(() => {
    if (
      dateRangeVal[0] != null &&
      dateRangeVal[1] != null &&
      searchDate !== 0 &&
      adsenseAccount.length !== 0
    ) {
      const getData = async () => {
        setLoadingScreen(true);
        setTableData([]);
        let res;

        const channelId = [];
        selectChannelOptions.forEach((obj) => {
          if (selectedChannel.includes(obj.value)) channelId.push(obj.value);
        });

        const requestBody = {
          clientName: userName,
          dateStart: dateRangeVal[0],
          dateEnd: dateRangeVal[1],
          countryName:
            selectedCountry.length === 0 ? ["All Countries"] : selectedCountry,
          channelName:
            selectedChannel.length === 0 ? ["All Channels"] : channelId,
          geodata: geoToggle,
          accountName: adsenseAccount.toUpperCase(),
          currencyCode: !currConvertToggle
            ? clientCurrency[0]
            : clientCurrency[1],
        };

        // Conditionally add timezone property based on multiUser value
        if (multiUser) {
          requestBody.timezone = timezone;
        }

        try {
          res = await axios.post(
            `${process.env.REACT_APP_ADSENSE_URL}/get_adsense_data`,
            {
              data: requestBody,
            },
            {
              headers: {
                // AuthKey: `${process.env.REACT_APP_AFS_KEY}`,
                Authorization: token,
              },
            }
          );
          res.data = res.data.response;

          setTableData(res.data.data);
          setRevGraph(res.data.revGraph);
          setEarning(res.data.rev.toFixed(2));

          setImpGraph(res.data.impGraph);
          setImp(res.data.imp);
          setClicksGraph(res.data.clicksGraph);
          setClicks(res.data.clicks);
          setPvGraph(res.data.pvGraph);
          setPv(res.data.pv);
          setAvgCpc(res.data.cpc);
          setAvgCtr(res.data.ctr);
          setLastUpdated(res.data.updated_on);

          setTzText(res.data.timezone);
          if ("revShare" in res.data) setClientRevShare(res.data.revShare);

          setLoadingScreen(false);
        } catch (err) {
          // Handle error and retry logic
          console.error("Request failed:", err.message);

          if (Object.keys(cookies).length === 0) retryCountRef.current = 2;

          if (retryCountRef.current < 2) {
            // Retry the request after a delay (e.g., 5 seconds)
            setTimeout(() => {
              retryCountRef.current += 1;
              getData();
            }, 5000);
          } else {
            console.log("errorMessage of max retries");
            errorMessage();
            console.error("Max retry count reached. Unable to fetch data.");
          }
        }
      };
      getData();

      setFilteredColumns(filterTableColumns());

      intervalRef.current = setInterval(() => {
        getData();
      }, 900000);

      // Cleanup function to cancel the timers if the component unmounts or if the user navigates away
      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [
    searchDate,
    filterSubmit,
    geoToggle,
    currConvertToggle,
    timezone,
    adsenseAccount,
  ]);

  useEffect(() => {
    if (Object.keys(channelData).length !== 0)
      setSelectChannelOptions(channelData[adsenseAccount][timezone]);
  }, [channelData, adsenseAccount]);

  const handleQuickDateChange = (days) => {
    const today = new Date();
    const d1 = createDate(today);

    const startDate = new Date(today);
    startDate.setDate(today.getDate() - (days - 1));
    const d2 = createDate(startDate);

    const date1 = d2[2] + "-" + d2[1] + "-" + d2[0];
    const date2 = d1[2] + "-" + d1[1] + "-" + d1[0];

    setDateRangeVal([date1, date2]);
    setSearchDate(!searchDate);
    setQuickDate(`${days}D`);
  };

  const flattenData = (data) => {
    const result = [];
    const flatten = (record, parentKey) => {
      const { key, children, ...rest } = record;
      const currentKey = parentKey ? `${parentKey}.${key}` : key;

      const formattedData = Object.entries(rest).reduce(
        (acc, [property, value]) => {
          if (
            isNaN(value) &&
            property !== "date" &&
            property !== "channelName" &&
            property !== "countryName" &&
            property !== "website"
          ) {
            acc[property] = 0;
          } else if (typeof value === "number") {
            acc[property] = value.toFixed(2);
          } else {
            acc[property] = value;
          }
          return acc;
        },
        {}
      );
      if (clientRevShare !== 1) {
        if (geoToggle) {
          result.push({
            key: currentKey,
            Date: formattedData.date,
            "Channel Name": formattedData.channelName,
            Country: formattedData.countryName,
            "Your Share (USD)": clientRevShare * formattedData.rev,
            "Estimated Earnings (USD)": formattedData.rev,
            "Page Views": formattedData.pv,
            Impressions: formattedData.imp,
            "CTR (%)": formattedData.impCTR,
            clicks: formattedData.clicks,
            CPC: formattedData.cpc,
          });
        } else {
          result.push({
            key: currentKey,
            Date: formattedData.date,
            "Channel Name": formattedData.channelName,
            "Your Share (USD)": clientRevShare * formattedData.rev,
            "Estimated Earnings (USD)": formattedData.rev,
            "Page Views": formattedData.pv,
            Impressions: formattedData.imp,
            "CTR (%)": formattedData.impCTR,
            clicks: formattedData.clicks,
            CPC: formattedData.cpc,
          });
        }
      } else {
        if (geoToggle) {
          result.push({
            key: currentKey,
            Date: formattedData.date,
            "Channel Name": formattedData.channelName,
            Country: formattedData.countryName,
            "Estimated Earnings (USD)": formattedData.rev,
            "Page Views": formattedData.pv,
            Impressions: formattedData.imp,
            "CTR (%)": formattedData.impCTR,
            clicks: formattedData.clicks,
            CPC: formattedData.cpc,
          });
        } else {
          result.push({
            key: currentKey,
            Date: formattedData.date,
            "Channel Name": formattedData.channelName,
            "Estimated Earnings (USD)": formattedData.rev,
            "Page Views": formattedData.pv,
            Impressions: formattedData.imp,
            "CTR (%)": formattedData.impCTR,
            clicks: formattedData.clicks,
            CPC: formattedData.cpc,
          });
        }
      }

      if (children) {
        children.forEach((child) => flatten(child, currentKey));
      }
    };
    data.forEach((record) => flatten(record));
    result.push({
      key: 100001,
      "Channel Name": "Total",
      "Estimated Earnings (USD)": earning,
      "Page Views": Pv,
      Impressions: imp,
      "CTR (%)": avgCtr,
      clicks: clicks,
      CPC: avgCpc,
    });
    return result;
  };

  const flattenedData = flattenData(tableData).map((record) => {
    delete record.key;
    return record;
  });

  const handleLogout = () => {
    removeCookie("my_token");
    destroyToken(token, userName);
    navigate("/login");
  };

  const shiftDateRange = (direction) => {
    if (dateRangeVal[0] && dateRangeVal[1]) {
      const newStartDate =
        direction === "left"
          ? dayjs(dateRangeVal[0]).subtract(1, "day")
          : dayjs(dateRangeVal[0]).add(1, "day");

      const newEndDate =
        direction === "left"
          ? dayjs(dateRangeVal[1]).subtract(1, "day")
          : dayjs(dateRangeVal[1]).add(1, "day");

      setDateRangeVal([
        newStartDate.format("YYYY-MM-DD"),
        newEndDate.format("YYYY-MM-DD"),
      ]);
      setSearchDate(searchDate + 1);
    }
  };

  const handleSelectAll = () => {
    if (channelCheck) {
      setChannelCheck(false);
      setSelectedChannel([]);
    } else {
      setChannelCheck(true);
      const matchingOptions = selectChannelOptions.filter((option) =>
        filterOptions(searchInput, option)
      );
      const matchingValues = matchingOptions.map((option) => option.value);
      setSelectedChannel(matchingValues);
      setSearchInput("");
    }
  };

  const filterOptions = (input, option) => {
    return (
      option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
      option.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  const CustomTooltip = ({ active, payload, label, coordinate, viewBox }) => {
    if (active && payload && payload.length) {
      const chartMidPoint = (viewBox.width * 2) / 3;
      const isRightHalf = coordinate.x > chartMidPoint;

      return (
        <div
          className="recharts-default-tooltip"
          style={{
            backgroundColor: "white",
            border: "1px solid #ccc",
            paddingLeft: "10px",
            paddingRight: "10px",
            left: isRightHalf ? coordinate.x - 95 : coordinate.x + 10,
          }}
        >
          <p className="recharts-tooltip-label mt-2 mb-1">{label}</p>
          {payload.map((entry, index) => (
            <p
              key={`item-${index}`}
              style={{ color: entry.color, marginTop: 0 }}
            >
              {`${entry.name} : ${entry.value}`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {loadingScreen ? (
        <>
          {contextHolder}
          <LoaderComp fullScreen={true} />
        </>
      ) : (
        <div className="w-full">
          {contextHolder}
          <div className=" bg-gray-100">
            <div
              className={`grid gap-1 grid-cols-1 lg:grid-cols-5  justify-between items-center mb-3 bg-white`}
            >
              <div className="flex justify-center">{getLogo(isUnibots)}</div>

              <div className="flex justify-center lg:col-span-2">
                <div className="flex items-center my-3">
                  <Button
                    style={{ marginRight: 4 }}
                    icon={<LeftOutlined />}
                    onClick={() => shiftDateRange("left")}
                  />
                  <Space direction="vertical" size={12}>
                    <RangePicker
                      value={
                        dateRangeVal[0] !== "" && dateRangeVal[1] !== ""
                          ? [dayjs(dateRangeVal[0]), dayjs(dateRangeVal[1])]
                          : undefined
                      }
                      onChange={(date, dateString) => {
                        setDateRangeVal(dateString);
                        setQuickDate("");
                      }}
                    />
                  </Space>
                  <Button
                    style={{ marginLeft: 4, marginRight: 4 }}
                    type="primary"
                    onClick={() => setSearchDate(searchDate + 1)}
                  >
                    Search
                  </Button>
                  <Button
                    icon={<RightOutlined />}
                    onClick={() => shiftDateRange("right")}
                  />
                </div>
              </div>

              <div className="flex justify-center">
                <Button
                  size="small"
                  onClick={() => handleQuickDateChange(1)}
                  type={quickDate === "1D" ? "primary" : "default"}
                >
                  24H
                </Button>

                <Button
                  size="small"
                  onClick={() => handleQuickDateChange(7)}
                  type={quickDate === "7D" ? "primary" : "default"}
                >
                  7D
                </Button>

                <Button
                  size="small"
                  onClick={() => handleQuickDateChange(30)}
                  type={quickDate === "30D" ? "primary" : "default"}
                >
                  30D
                </Button>
              </div>

              <div className="flex justify-center">
                <Button
                  danger
                  type="text"
                  size="large"
                  icon={<LogoutOutlined />}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </div>
            </div>

            <div>
              <div>
                <div
                  className={`grid grid-cols-1 lg:grid-cols-4 justify-between items-center my-4 h-auto lg:h-72 mx-4`}
                >
                  <div className="flex flex-col lg:flex-row lg:col-span-3 h-full w-full order-2 lg:order-1 rounded-lg bg-white lg:pt-3 lg:pr-4">
                    <div className="block h-64 lg:h-full w-full ">
                      <div className="flex justify-center h-5/6 w-full">
                        <ResponsiveContainer width="100%" height="100%">
                          <AreaChart
                            data={revGraph}
                            syncId="syncedTooltip"
                            margin={{ top: 10, bottom: 10, right: 20 }}
                          >
                            <defs>
                              <linearGradient
                                id="colorImp"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                              >
                                <stop
                                  offset="5%"
                                  stopColor="#2e7d32"
                                  stopOpacity={0.4}
                                />
                                <stop
                                  offset="95%"
                                  stopColor="#2e7d32"
                                  stopOpacity={0}
                                />
                              </linearGradient>
                            </defs>
                            <XAxis dataKey="name" />
                            <YAxis />
                            {/* <Tooltip  /> */}
                            <Tooltip content={<CustomTooltip />} />
                            <Area
                              type="monotone"
                              dataKey="Earning"
                              stroke="#2e7d32"
                              fillOpacity={1}
                              fill="url(#colorImp)"
                            />
                          </AreaChart>
                        </ResponsiveContainer>
                      </div>
                      <div className="flex justify-center">
                        <Tag
                          color="green"
                          style={{
                            padding: "6px",
                            fontSize: "13px",
                            borderRadius: "8px",
                          }}
                        >
                          Earning:{" "}
                          {getCurrencySymbol(
                            currConvertToggle
                              ? clientCurrency[1]
                              : clientCurrency[0]
                          )}
                          {earning}
                        </Tag>
                      </div>
                    </div>

                    <div className="block h-64 lg:h-full w-full">
                      <div className="flex justify-center h-5/6 w-full">
                        <ResponsiveContainer width="100%" height="100%">
                          <AreaChart
                            data={
                              multiUser || userName === "facebook"
                                ? clicksGraph
                                : pvGraph
                            }
                            syncId="syncedTooltip"
                            margin={{ top: 10, bottom: 10, right: 20 }}
                          >
                            <defs>
                              <linearGradient
                                id="colorRev"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                              >
                                <stop
                                  offset="5%"
                                  stopColor="#1976d2"
                                  stopOpacity={0.4}
                                />
                                <stop
                                  offset="95%"
                                  stopColor="#1976d2"
                                  stopOpacity={0}
                                />
                              </linearGradient>
                            </defs>

                            <XAxis
                              dataKey="name"
                              style={{ marginLeft: "50px" }}
                            />
                            <YAxis />
                            {/* <Tooltip /> */}
                            <Tooltip content={<CustomTooltip />} />
                            <Area
                              type="monotone"
                              dataKey={
                                userName === "kolemedia"
                                  ? "cpc"
                                  : multiUser || userName === "facebook"
                                  ? "clicks"
                                  : "pv"
                              }
                              stroke="#1976d2"
                              fillOpacity={1}
                              fill="url(#colorRev)"
                            />
                          </AreaChart>
                        </ResponsiveContainer>
                      </div>
                      <div className="flex justify-center">
                        <Tag
                          color="blue"
                          style={{
                            padding: "6px",
                            fontSize: "13px",
                            borderRadius: "8px",
                          }}
                        >
                          {userName === "kolemedia"
                            ? `CPC: $${Pv}`
                            : multiUser || userName === "facebook"
                            ? `Clicks: ${clicks}`
                            : `Page Views: ${Pv}`}
                        </Tag>
                      </div>
                    </div>

                    <div className="block h-64 lg:h-full w-full">
                      <div className="flex justify-center h-5/6 w-full">
                        <ResponsiveContainer width="100%" height="100%">
                          <AreaChart
                            data={impGraph}
                            syncId="syncedTooltip"
                            margin={{ top: 10, bottom: 10, right: 20 }}
                          >
                            <defs>
                              <linearGradient
                                id="colorRpm"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                              >
                                <stop
                                  offset="5%"
                                  stopColor="#e83462"
                                  stopOpacity={0.4}
                                />
                                <stop
                                  offset="95%"
                                  stopColor="#e83462"
                                  stopOpacity={0}
                                />
                              </linearGradient>
                            </defs>
                            <XAxis dataKey="name" />
                            <YAxis />
                            {/* <Tooltip /> */}
                            <Tooltip content={<CustomTooltip />} />
                            <Area
                              type="monotone"
                              dataKey={
                                userName === "kolemedia" ? "impCTR" : "Imp"
                              }
                              stroke="#e83462"
                              fillOpacity={1}
                              fill="url(#colorRpm)"
                            />
                          </AreaChart>
                        </ResponsiveContainer>
                      </div>
                      <div className="flex justify-center">
                        <Tag
                          color="error"
                          style={{
                            padding: "6px",
                            fontSize: "13px",
                            borderRadius: "8px",
                          }}
                        >
                          {userName === "kolemedia"
                            ? `CTR: ${imp}`
                            : `Ad Impressions: ${imp}`}
                        </Tag>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col justify-center lg:justify-evenly bg-white h-full mb-4 lg:mb-0 lg:ml-8 rounded-lg gap-y-3 order-1 lg:order-2 lg:py-0 lg:pt-3">
                    {multiUser && (
                      <div className="flex flex-row justify-center items-center gap-4">
                        <div>
                          <Button
                            size="small"
                            onClick={() => {
                              setTimezone("ist");
                            }}
                            type={timezone === "ist" ? "primary" : "default"}
                          >
                            Ind
                          </Button>
                          <Button
                            size="small"
                            onClick={() => {
                              setTimezone("pst");
                            }}
                            type={timezone === "pst" ? "primary" : "default"}
                          >
                            US
                          </Button>
                        </div>

                        <div>
                          <Button
                            onClick={() => setAdsenseAccount("sip")}
                            disabled={disabledOptions.includes("sip")}
                            size="small"
                            type={
                              adsenseAccount === "sip" ? "primary" : "default"
                            }
                          >
                            SIP
                          </Button>

                          <Button
                            onClick={() => setAdsenseAccount("poa")}
                            disabled={disabledOptions.includes("poa")}
                            size="small"
                            type={
                              adsenseAccount === "poa" ? "primary" : "default"
                            }
                          >
                            POA
                          </Button>
                        </div>
                      </div>
                    )}
                    <div className="flex justify-evenly">
                      {currToggleShow && (
                        <div className="flex space-x-2 justify-center ">
                          <Radio.Group
                            size="small"
                            options={[
                              {
                                label: `${clientCurrency[0]}`,
                                value: `${clientCurrency[0]}`,
                              },
                              {
                                label: `${clientCurrency[1]}`,
                                value: `${clientCurrency[1]}`,
                              },
                            ]}
                            onChange={() =>
                              setCurrConvertToggle(!currConvertToggle)
                            }
                            value={
                              currConvertToggle
                                ? `${clientCurrency[1]}`
                                : `${clientCurrency[0]}`
                            }
                            optionType="button"
                            buttonStyle="solid"
                          />
                        </div>
                      )}
                      <div className="flex space-x-2 justify-center ">
                        <Radio.Group
                          size="small"
                          options={[
                            {
                              label: "Regular",
                              value: "Regular",
                            },
                            {
                              label: "Geowise",
                              value: "Geowise",
                            },
                          ]}
                          onChange={() => {
                            if (geoToggle) {
                              setSelectedCountry([]);
                              setSelectedChannel([]);
                            }
                            setGeoToggle(!geoToggle);
                          }}
                          value={geoToggle ? "Geowise" : "Regular"}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </div>
                    </div>

                    <div>
                      <div className="flex align-top justify-center pb-2">
                        {!multiUser && userName !== "Pervin" ? (
                          <Select
                            mode="multiple"
                            showSearch
                            style={{
                              width: "80%",
                              maxWidth: 420,
                              maxHeight: 120,
                              overflowY: "auto",
                            }}
                            placeholder="Select Channel"
                            // disabled={!geoToggle}
                            options={selectChannelOptions}
                            filterOption={(input, option) =>
                              option.value
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.id
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            value={selectedChannel}
                            onChange={(val) => {
                              setSelectedChannel(val);
                            }}
                            maxTagCount={3}
                            maxTagPlaceholder={(omittedValues) =>
                              `+${omittedValues.length} more`
                            }
                          ></Select>
                        ) : (
                          <Select
                            mode="multiple"
                            showSearch
                            style={{
                              width: "80%",
                              maxWidth: 420,
                              maxHeight: 120,
                              overflowY: "auto",
                            }}
                            placeholder="Select Channel"
                            options={selectChannelOptions}
                            filterOption={filterOptions}
                            value={selectedChannel}
                            onChange={(val) => {
                              setSearchInput("");
                              setChannelCheck(false);
                              setSelectedChannel(val);
                            }}
                            onSearch={(value) => {
                              setSearchInput(value);
                            }}
                            maxTagCount={3}
                            maxTagPlaceholder={(omittedValues) =>
                              `+${omittedValues.length} more`
                            }
                            dropdownRender={(menu) => (
                              <div>
                                <div
                                  style={{
                                    padding: "8px",
                                    borderBottom: "1px solid #e8e8e8",
                                  }}
                                >
                                  <Checkbox
                                    checked={channelCheck}
                                    onChange={handleSelectAll}
                                  >
                                    Select All Matching
                                  </Checkbox>
                                </div>
                                {menu}
                              </div>
                            )}
                          />
                        )}
                      </div>
                      <div className="flex align-top justify-center pb-2">
                        <Select
                          mode="multiple"
                          showSearch
                          style={{
                            width: "80%",
                            maxWidth: 420,
                            maxHeight: 80,
                            overflowY: "auto",
                          }}
                          placeholder="Select Country"
                          options={selectCountryOptions}
                          value={selectedCountry}
                          disabled={!geoToggle}
                          onChange={(val) => {
                            setSelectedCountry(val);
                          }}
                        ></Select>
                      </div>
                      <div className="flex align-top justify-center">
                        <Button
                          type="primary"
                          // disabled={!geoToggle}
                          sx={{ px: 2 }}
                          onClick={() => setFilterSubmit(!filterSubmit)}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className={`flex justify-between mb-2 px-4`}> */}
                <div
                  className={`grid grid-cols-2 md:grid-cols-3 justify-evenly mb-2 px-4`}
                >
                  <div className="flex justify items-center">
                    <Text keyboard strong>
                      {`Updated: ${lastUpdated}`}
                    </Text>
                  </div>
                  <div className="flex justify-end md:justify-center items-center">
                    <Text keyboard strong>
                      {`Timezone: ${tzText.toUpperCase()}`}
                    </Text>
                  </div>
                  <div className="flex md:justify-end items-end my-2 lg:my-0">
                    <Button
                      type="primary"
                      style={{
                        backgroundColor: "#1B5E20",
                        borderColor: "#1B5E20",
                      }}
                    >
                      <CSVLink
                        filename={"DataTable.csv"}
                        data={flattenedData}
                        className="btn btn-primary"
                        style={{ textDecoration: "none" }} // Remove underline from text
                      >
                        <Typography variant="h5" sx={{ color: "#FFFFFF" }}>
                          <span style={{ color: "#FFFFFF" }}>
                            Export to CSV
                          </span>
                        </Typography>
                      </CSVLink>
                    </Button>
                  </div>
                </div>

                <div className="flex mx-4">
                  <Table
                    bordered
                    columns={filteredColumns}
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "50", "100"],
                    }}
                    dataSource={tableData}
                    scroll={{
                      x: 1000,
                      y: 850,
                    }}
                    summary={() => {
                      return (
                        <>
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                              <Text strong>Total</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}></Table.Summary.Cell>
                            <Table.Summary.Cell index={2}></Table.Summary.Cell>
                            {geoToggle && (
                              <Table.Summary.Cell
                                index={3}
                              ></Table.Summary.Cell>
                            )}
                            <Table.Summary.Cell index={geoToggle ? 4 : 3}>
                              <Text strong>
                                {getCurrencySymbol(
                                  currConvertToggle
                                    ? clientCurrency[1]
                                    : clientCurrency[0]
                                )}
                                {earning}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={geoToggle ? 5 : 4}>
                              <Text strong>{Pv}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={geoToggle ? 6 : 5}>
                              <Text strong>{imp}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={geoToggle ? 7 : 6}>
                              <Text strong>
                                {avgCtr}%{" "}
                                <span
                                  style={{
                                    fontSize: "0.8em",
                                    color: "#a9a9a9",
                                  }}
                                >
                                  avg
                                </span>
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={geoToggle ? 8 : 7}>
                              <Text strong>{clicks}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={geoToggle ? 9 : 8}>
                              <Text strong>
                                {getCurrencySymbol(
                                  currConvertToggle
                                    ? clientCurrency[1]
                                    : clientCurrency[0]
                                ) + Number(avgCpc).toFixed(2)}{" "}
                                <span
                                  style={{
                                    fontSize: "0.8em",
                                    color: "#a9a9a9",
                                  }}
                                >
                                  avg
                                </span>
                              </Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AFSDash;
